import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const ResponsableSeguridadForm = Loadable(lazy(() => import('views/responsableSeguridad/tableSolicitudesSeguridad')));
const responsableSeguridad = {
    path: '/responsableSeguridad',
    element: <MainLayout />,
    children: [
        {
            path: '/solicitud',
            element: <ResponsableSeguridadForm />
        }
    ]
};

export default responsableSeguridad;

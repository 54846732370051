import { useState } from 'react';

const useLoader = (initialState = false) => {
    const [loading, setLoading] = useState(initialState);

    const startLoading = () => setLoading(true);
    const stopLoading = () => setLoading(false);

    return { loading, startLoading, stopLoading };
};

export default useLoader;

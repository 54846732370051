import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// routing
const VentasListView = Loadable(lazy(() => import('views/ventas/listar')));
const VentasView = Loadable(lazy(() => import('views/ventas/registrar')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/ventas',
    element: <MainLayout />,
    children: [
        {
            path: '/listar',
            element: <VentasListView />
        },
        {
            path: '/registrar',
            element: <VentasView />
        }
    ]
};

export default MainRoutes;

// assets
import { IconFileAnalytics, IconTruckDelivery, IconFileImport, IconHistory } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const responsableSeguridad = {
    id: 'responsable_seguridad',
    type: 'group',
    order: 1,
    children: [
        // {
        //     id: '01',
        //     title: 'Backoffice',
        //     type: 'item',
        //     icon: IconFileAnalytics,
        //     children: [
        {
            id: '0501',
            title: 'Solicitudes',
            type: 'item',
            icon: IconFileAnalytics,
            url: '/responsableSeguridad/solicitud'
        }
        // {
        //     id: '0103',
        //     title: 'Usuario',
        //     type: 'item',
        //     icon: IconTruckDelivery,
        //     url: '/coordinadorMantenimiento/usuario'
        // }
    ]
    //     }
    // ]
};

export default responsableSeguridad;

import { memo, useState } from 'react';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import LocalText from 'utils/locale_text';
import TableLoading from 'components/TableLoading';
import TableNoDataOverlay from 'components/TableNoDataOverlay';
import CustomButton from 'components/Button';

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    }
})((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

const TableCuentas = ({ cuentas, setCuenta, showModalDetalles, showModalEditar }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const columns = [
        {
            field: 'bank',
            valueGetter: ({ value: field }) => field.description,
            headerName: 'Banco',
            flex: 1,
            minWidth: 250
        },
        {
            field: 'type',
            valueGetter: ({ value: field }) => (field === 'S' ? 'Soles' : 'Dólares Americanos'),
            headerName: 'Tipo',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'number',
            headerName: 'N°',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'cci',
            headerName: 'CCI',
            minWidth: 100,
            flex: 1
        },
        {
            field: 'state',
            // valueGetter: ({ value: field }) => (field === 'A' ? 'Activo' : 'Inactivo'),
            headerName: 'Estado',
            flex: 1,
            minWidth: 80,
            renderCell: (params) => (
                <div
                    style={{
                        height: '30px',
                        border: 'solid 1px',
                        color: params.row.state === 'A' ? 'rgb(138, 194, 6)' : '#A0A0A0',
                        fontWeight: 'bold',
                        width: '80px',
                        margin: '0 auto',
                        textAlign: 'center',
                        paddingTop: '3px',
                        borderRadius: '4px'
                        // backgroundColor: params.row.state === 'A' ? 'rgb(138, 194, 6)' : 'red'
                    }}
                >
                    {params.row.state === 'A' ? 'Activo' : 'Inactivo'}
                </div>
            )
        },
        {
            field: 'acciones',
            headerName: '',
            sortable: false,
            flex: 1,
            minWidth: 120,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const thisRow = params.row;
                const getProd = () => {
                    console.log(thisRow);
                    // console.log(APIQueryQuotes.data);
                    const prod = cuentas.find((field) => field.id === thisRow.id);
                    setCuenta(prod);
                };

                const handleClick = (event) => {
                    setAnchorEl(event.currentTarget);
                    getProd();
                };
                const handleClose = () => {
                    setAnchorEl(null);
                };

                const clickDetalle = () => {
                    handleClose();
                    showModalDetalles();
                };

                const clickEditar = () => {
                    handleClose();
                    showModalEditar();
                };

                return (
                    <div>
                        <CustomButton
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            onClick={handleClick}
                            sx={{ padding: '.5rem 0.9rem' }}
                        >
                            ACCIONES
                        </CustomButton>
                        <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                            <StyledMenuItem onClick={clickDetalle}>
                                <ListItemIcon>
                                    <FormatListBulletedRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Ver Detalle" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={clickEditar}>
                                <ListItemIcon>
                                    <BorderColorRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Editar" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </div>
                );
            }
        }
    ];

    return (
        <Box style={{ width: '100%', marginTop: '0px' }}>
            <DataGrid
                autoHeight
                hideFooterPagination={false}
                disableSelectionOnClick
                defaultColDef={{ resizable: true }}
                localeText={LocalText}
                columns={columns}
                rows={cuentas}
                density="comfortable"
                getRowId={(row) => row.id}
                // autoHeight
                components={{
                    LoadingOverlay: TableLoading,
                    NoRowsOverlay: TableNoDataOverlay
                    // Toolbar: CustomToolbar
                }}
            />
        </Box>
    );
};

TableCuentas.propTypes = {
    cuentas: PropTypes.array.isRequired,
    setCuenta: PropTypes.func.isRequired,
    showModalDetalles: PropTypes.func,
    showModalEditar: PropTypes.func
};

export default memo(TableCuentas);

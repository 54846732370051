import { useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Box, FormControl, FormHelperText, IconButton, InputAdornment, TextField, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomLoadingButton from 'components/Button/LoadingButton';
import useLoader from 'hooks/useLoader';

const formHeight = '80px';

const AuthSetPassword = ({ updatePassword }) => {
    const [error, setError] = useState(null);
    const loader = useLoader();
    // const { user, loading, updatePassword, error } = useAuth();

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const handleClickShowPassword = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const handleSubmit = async (values) => {
        const { password, confirmPassword } = values;

        try {
            loader.startLoading();
            await updatePassword(password, confirmPassword);
        } catch (error) {
            setError(error.message);
        } finally {
            loader.stopLoading();
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().max(25, 'La contraseña es demasiado larga.').required('Este campo es obligatorio.'),
                    confirmPassword: Yup.string().max(25, 'La contraseña es demasiado larga.').required('Este campo es obligatorio.')
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        {error && (
                            <Box sx={{ my: 2 }}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}

                        <FormControl fullWidth sx={{ height: formHeight }} error={Boolean(touched.password && errors.password)}>
                            <TextField
                                id="outlined-adornment-password-login"
                                type={showPassword.password ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                label="Contraseña"
                                onChange={(e) => {
                                    handleChange(e);
                                    changePassword(e.target.value);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="start"
                                                size="large"
                                            >
                                                {showPassword.password ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {/* {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )} */}

                        <FormControl fullWidth sx={{ height: formHeight }} error={Boolean(touched.password && errors.password)}>
                            <TextField
                                id="outlined-adornment-password-login"
                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                value={values.confirmPassword}
                                name="confirmPassword"
                                onBlur={handleBlur}
                                label="Confirmar contraseña"
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('confirmPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="start"
                                                size="large"
                                            >
                                                {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <CustomLoadingButton
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={loader.loading}
                                >
                                    Actualizar Contraseña
                                </CustomLoadingButton>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

AuthSetPassword.propTypes = {
    updatePassword: PropTypes.func.isRequired
};

export default AuthSetPassword;

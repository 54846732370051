/* eslint-disable */
import axios from 'axios';
import config from 'config';
import { Observable } from 'rxjs';
import { ServerError } from './ServerError';

const axiosInstance = axios.create({
    baseURL: config.api.url
});

// axiosInstance.interceptors.request.use((config) => {
//     return { ...config, headers: { ...config.headers, Authorization: Http.getBearerAuthToken() } };
// });

class Http {
    static AxiosInstance = axiosInstance;
    static _accessToken = null;
    static _refreshToken = null;
    static _subscribers = null;
    static _config = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    static httpDefaultOptions = {
        includeAccessToken: true,
        includeRefreshToken: false,
        disableTokenRefresh: false
    };

    static onRefreshToken() {
        return new Observable(function (subs) {
            Http._subscribers = subs;
        });
    }

    static _updateToken(error, data) {
        if (!Http._subscribers) return;

        if (error) {
            return Http._subscribers.error(error);
        }

        return Http._subscribers.next(data);
    }

    static getHttpOptions(axiosOptions = {}, options = {}) {
        let axiosOpt = { ...Http._config, ...axiosOptions, headers: { ...Http._config.headers, ...axiosOptions.headers } };
        let customHttpOptions = {
            ...Http.httpDefaultOptions,
            ...options
        };

        if (customHttpOptions.includeAccessToken) {
            axiosOpt = { ...axiosOpt, headers: { ...Http.getHeadersWithBearerToken(Http.getAccessToken(), axiosOpt.headers) } };
        }

        if (customHttpOptions.includeRefreshToken) {
            axiosOpt = { ...axiosOpt, headers: { ...Http.getHeadersWithBearerToken(Http.getRefreshToken(), axiosOpt.headers) } };
        }

        return axiosOpt;
    }

    static async get(url, axiosOptions = {}, httpOptions = {}) {
        // const options = Http.getHttpOptions(axiosOptions, httpOptions);
        return Http.exec({
            url,
            data: null,
            method: 'GET',
            axiosOptions,
            httpOptions
        });
    }

    static async delete(url, axiosOptions = {}, httpOptions = {}) {
        // const options = Http.getHttpOptions(axiosOptions, httpOptions);
        return Http.exec({
            url,
            data: null,
            method: 'DELETE',
            axiosOptions,
            httpOptions
        });
    }

    static async post(url, data = {}, axiosOptions = {}, httpOptions = {}) {
        const axOpt = { headers: {}, ...axiosOptions };
        // const options = Http.getHttpOptions({}, httpOptions);
        return Http.exec({
            url,
            method: 'POST',
            data,
            axiosOptions: axOpt,
            httpOptions
        });
    }

    static async patch(url, data = {}, axiosOptions = {}, httpOptions = {}) {
        const axOpt = { headers: {}, ...axiosOptions };
        // const options = Http.getHttpOptions({}, httpOptions);
        return Http.exec({
            url,
            method: 'PATCH',
            data,
            axiosOptions: axOpt,
            httpOptions
        });
    }

    static getHeadersWithBearerToken(token, otherOptions) {
        return {
            ...Http._config.headers,
            ...otherOptions,
            Authorization: `Bearer ${token}`
        };
    }

    static async refreshToken() {
        const {
            data: {
                data: { accessToken, refreshToken }
            }
        } = await Http.get('/auth/refresh', {}, { includeRefreshToken: true });

        return {
            accessToken,
            refreshToken
        };
    }

    static exec({ url, method, axiosOptions, httpOptions, data }) {
        return new Promise(async (resolve, reject) => {
            try {
                const options = Http.getHttpOptions(axiosOptions, httpOptions);
                let response = null;

                switch (method) {
                    case 'GET':
                        response = await Http.AxiosInstance.get(url, options);
                        return resolve(response);
                    case 'POST':
                        response = await Http.AxiosInstance.post(url, data, options);
                        return resolve(response);
                    case 'PATCH':
                        response = await Http.AxiosInstance.patch(url, data, options);
                        return resolve(response);
                    case 'DELETE':
                        response = await Http.AxiosInstance.delete(url, options);
                        return resolve(response);
                    default:
                        return reject();
                }
            } catch (error) {
                const code = error?.response?.data?.code;

                if (httpOptions.disableTokenRefresh) {
                    return reject(error);
                }

                if (code === 'TOKEN_EXPIRED') {
                    try {
                        const { accessToken, refreshToken } = await Http.refreshToken();

                        Http.setAccessToken(accessToken);
                        Http.setRefreshToken(refreshToken);
                        Http._updateToken(null, { accessToken, refreshToken });
                    } catch (error) {
                        Http._updateToken(error, null);
                        return reject(error);
                    }

                    try {
                        const response = await Http.exec({ url, method, axiosOptions, httpOptions, data });
                        return resolve(response);
                    } catch (error) {
                        return reject(error);
                    }
                }

                reject(error);
            }
        });
    }

    static setAccessToken(token) {
        Http._accessToken = token;
    }

    static getAccessToken() {
        return Http._accessToken;
    }

    static setRefreshToken(token) {
        Http._refreshToken = token;
    }

    static getRefreshToken() {
        return Http._refreshToken;
    }
}

export default Http;

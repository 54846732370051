const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 20,
    api: {
        url: process.env.REACT_APP_API_LINK,
        publicPath: process.env.REACT_APP_API_LINK.replace(/\/api/gi, '')
    }
};

export default config;

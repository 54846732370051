import { useEffect, useState, useCallback, memo } from 'react';
import { Grid, TextField, FormControl, FormHelperText, Typography, MenuItem } from '@mui/material';

import AlertDialog from 'components/AlertDialog';
import CustomButton from 'components/Button';
import CustomOutlinedButton from 'components/Button/OutlinedButton';
import CustomLoadingButton from 'components/Button/LoadingButton';
import UploadImage from 'components/UploaderImage';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import { useFormik } from 'formik';
import useAuth from 'contexts/AuthContext';
import TableCuentas from './TableCuentas';

import * as Yup from 'yup';
import Http from 'http-client';
import { styled } from '@mui/material/styles';

import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';

import MainCard from 'ui-component/cards/MainCard';
import useAPIQuery from 'hooks/useAPIQuery';
import CustomModal from 'components/CustomModal';
import config from 'config';
import useModal from 'hooks/useModal';

import Detalles from './Detalles';
import Editar from './Editar';

const formHeight = '60px';

const Image = styled('img')(
    () => `
        display: block;
        width: 60%;
        max-width: 200px;
        min-width: 170px;
        height: auto;
        margin: 0 auto;
    `
);

const stylesModal = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    box: {
        display: 'flex',
        alignItems: 'center'
    }
};

const MisDatos = () => {
    const { user } = useAuth();
    const [dialogResult, setDialogResult] = useState({
        open: false,
        title: '',
        message: ''
    });
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [cuenta, setCuenta] = useState([]);
    const [razon, setRazon] = useState('');
    const [ruc, setRuc] = useState('');
    const [cuentasList, setCuentasList] = useState(user.socio.cuentas);
    const { open: openModalDetalles, show: showModalDetalles, hide: hideModalDetalles } = useModal();
    const { open: openModalEditar, show: showModalEditar, hide: hideModalEditar } = useModal();

    const APIQueryBanks = useAPIQuery({
        url: '/banks',
        data: []
    });

    const APIQuerySocio = useAPIQuery({
        url: `/socios/${user.socio?.id}`,
        data: {},
        socket: {
            handleEvents: [
                {
                    event: 'socios:update'
                },
                {
                    event: 'socios-cuentas:create'
                },
                {
                    event: 'socios-cuentas:update'
                }
            ]
        }
    });

    const states = [
        { id: 'A', value: 'Activo' },
        { id: 'I', value: 'Inactivo' }
    ];

    const type = [
        { id: 'S', value: 'Soles' },
        { id: 'D', value: 'Dólares Americanos' }
    ];

    /* const APIQueryCategory = useAPIQuery({
        url: '/product-categories',
        data: []
    }); */

    const FormSchema = Yup.object().shape({
        // razon: Yup.string().required('Requerido.'),
        // ruc: Yup.string().required('Requerido.'),
        direccion: Yup.string(),
        telefono: Yup.string(),
        correo: Yup.string()
    });

    const FormSchemaCuenta = Yup.object().shape({
        /* categoria: Yup.string()
            .oneOf(
                APIQueryCategory.data.map((typeCategoria) => typeCategoria.id),
                'Categoría no válida.'
            )
            .required('Requerido.'), */
        /* estado: Yup.string()
            .oneOf(
                typeEstado.map((typeEstado) => typeEstado.id),
                'Estado no válido.'
            )
            .required('Requerido.'), */
        banco: Yup.string().required('Requerido.'),
        tipo: Yup.string().required('Requerido.'),
        numero: Yup.string().required('Requerido.'),
        cci: Yup.string().required('Requerido.'),
        estado: Yup.string().required('Requerido.')
    });

    const openDialogResult = useCallback((title, message) => {
        setDialogResult({
            open: true,
            title,
            message
        });
    }, []);

    const closeDialogResult = useCallback(() => {
        setDialogResult({
            ...dialogResult,
            open: false
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            // razon: user.socio.razonSocial || '',
            // ruc: user.socio.id || '',
            direccion: '',
            telefono: '',
            correo: ''
        },
        validationSchema: FormSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const body = {
                direction: values.direccion,
                phone: values.telefono,
                email: values.correo
            };

            Http.patch(`/socios/${APIQuerySocio.data.id}`, body)
                .then(({ status }) => {
                    if (status === 200) {
                        // console.log(data);
                        const formData = new FormData();
                        formData.append('id', APIQuerySocio.data.id);
                        formData.append('image', file);

                        Http.post('/socios/upload-image', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(
                            ({ status }) => {
                                console.log(status);
                                if (status === 201) {
                                    openDialogResult('Socio actualizado', `Se actualizó "${APIQuerySocio.data.razonSocial}"`);
                                    // resetForm();
                                }
                            }
                        );
                        return;
                    }

                    openDialogResult('Algo salió mal', `No se pudo actualizar "${APIQuerySocio.data.razonSocial}"`);
                })
                .catch((error) => {
                    openDialogResult('Algo salió mal', `No se pudo actualizar "${APIQuerySocio.data.razonSocial}"`);
                    console.log(error.response);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    const formikCuenta = useFormik({
        initialValues: {
            banco: '',
            tipo: '',
            numero: '',
            cci: '',
            estado: ''
        },
        validationSchema: FormSchemaCuenta,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const body = {
                socioId: APIQuerySocio.data.id,
                bankId: values.banco,
                type: values.tipo,
                number: values.numero,
                cci: values.cci,
                state: values.estado
            };

            Http.post('/socios-cuentas', body)
                .then(({ status }) => {
                    if (status === 201) {
                        // console.log(data);
                        openDialogResult('Cuenta agregada', `Se agregó una nueva cuenta con éxito.`);
                        resetForm();
                        return;
                    }

                    openDialogResult('Algo salió mal', `No se pudo agregar la cuenta`);
                })
                .catch((error) => {
                    openDialogResult('Algo salió mal', `No se pudo agregar la cuenta`);
                    console.log(error.response);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    const handleChangeFocus = useCallback(({ target: { name, value } }) => {
        formik.setFieldValue(name, value?.trim(), true);
    }, []);

    const handleChangeFocusCuenta = useCallback(({ target: { name, value } }) => {
        formikCuenta.setFieldValue(name, value?.trim(), true);
    }, []);

    useEffect(() => {
        // APIQueryCategory.exec();
        if (APIQuerySocio.data.image) {
            const arrayExt = APIQuerySocio.data.image.split('.');
            console.log(arrayExt[1]);
            fetch(`${config.api.publicPath}/socios/${APIQuerySocio.data.id}/${APIQuerySocio.data.image}`).then((res) => {
                res.blob().then((blob) => {
                    // please change the file.extension with something more meaningful
                    // or create a utility function to parse from URL
                    const file = new File([blob], `file.${arrayExt[1]}`, { type: blob.type });
                    const url = URL.createObjectURL(file);
                    setImage(url);
                    setFile(file);
                    // resolve(file);
                });
            });
        }
        formik.setFieldValue('direccion', APIQuerySocio.data.direction || '', true);
        formik.setFieldValue('telefono', APIQuerySocio.data.phone || '', true);
        formik.setFieldValue('correo', APIQuerySocio.data.email || '', true);
        setRazon(APIQuerySocio.data.razonSocial || '');
        setRuc(APIQuerySocio.data.id || '');

        if (APIQuerySocio.data.cuentas) {
            setCuentasList(APIQuerySocio.data.cuentas);
        }

        console.log(APIQuerySocio.data);
    }, [APIQuerySocio.data]);

    useEffect(() => {
        APIQuerySocio.exec();
        APIQueryBanks.exec();
    }, []);

    return (
        <>
            <MainCard>
                <Typography sx={{ fontWeight: 'bold', color: ' #555' }} variant="h3">
                    Datos de Empresa
                </Typography>
                <Grid height={20} />
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid item container direction="row" justifyContent="center" spacing={1.5} xs={12}>
                        <Grid item container spacing={1} alignItems="center" justifyContent="center" xs={12} sm={12} md={8}>
                            <Grid item xs={12} sm={6} md={8}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        disabled
                                        sx={{
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: 'black'
                                            }
                                        }}
                                        name="razon"
                                        size="small"
                                        className="input_number_without_spin"
                                        type="text"
                                        label="Razón Social"
                                        value={razon}
                                        // onChange={formik.handleChange}
                                        // onBlur={handleChangeFocus}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        disabled
                                        sx={{
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: 'black'
                                            }
                                        }}
                                        name="ruc"
                                        size="small"
                                        value={ruc}
                                        // onChange={formik.handleChange}
                                        // onBlur={handleChangeFocus}
                                        type="text"
                                        label="RUC"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={9} sm={6} md={8}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        name="direccion"
                                        size="small"
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={handleChangeFocus}
                                        type="text"
                                        label="Dirección"
                                    />
                                    {formik.touched.direccion && formik.errors.direccion && (
                                        <FormHelperText error>{formik.errors.direccion}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        name="telefono"
                                        size="small"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={handleChangeFocus}
                                        type="text"
                                        label="Teléfono"
                                    />
                                    {formik.touched.telefono && formik.errors.telefono && (
                                        <FormHelperText error>{formik.errors.telefono}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={9} sm={6} md={6}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        name="correo"
                                        size="small"
                                        value={formik.values.correo}
                                        onChange={formik.handleChange}
                                        onBlur={handleChangeFocus}
                                        type="text"
                                        label="Correo"
                                    />
                                    {formik.touched.correo && formik.errors.correo && (
                                        <FormHelperText error>{formik.errors.correo}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <CustomLoadingButton
                                        loading={formik.isSubmitting}
                                        type="submit"
                                        startIcon={<SaveRoundedIcon />}
                                        // sx={{ width: '160px' }}
                                        variant="contained"
                                    >
                                        Guardar
                                    </CustomLoadingButton>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <CustomOutlinedButton
                                        type="button"
                                        // sx={{ width: '160px' }}
                                        variant="outlined"
                                        startIcon={<CleaningServicesRoundedIcon />}
                                        onClick={() => {
                                            formik.resetForm();
                                        }}
                                    >
                                        Limpiar
                                    </CustomOutlinedButton>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <UploadImage
                                onResult={(file) => {
                                    const url = URL.createObjectURL(file);
                                    setImage(url);
                                    setFile(file);
                                }}
                            >
                                {image != null ? <Image src={image} alt="" /> : <FileUploadRoundedIcon fontSize="large" color="primary" />}
                            </UploadImage>
                        </Grid>
                    </Grid>
                </form>
                <Grid height={30} />
                <Typography sx={{ fontWeight: 'bold', color: ' #555' }} variant="h3">
                    Cuentas Bancarias
                </Typography>
                <Grid height={20} />
                <form noValidate onSubmit={formikCuenta.handleSubmit}>
                    <Grid item container direction="row" justifyContent="center" spacing={1.5} xs={12}>
                        <Grid item container spacing={1} alignItems="center" justifyContent="center" xs={12} sm={12} md={12}>
                            <Grid item xs={12} sm={6} md={8}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        select
                                        name="banco"
                                        size="small"
                                        label="Banco"
                                        value={formikCuenta.values.banco}
                                        onChange={formikCuenta.handleChange}
                                        onBlur={handleChangeFocusCuenta}
                                    >
                                        {APIQueryBanks.data.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {formikCuenta.touched.banco && formikCuenta.errors.banco && (
                                        <FormHelperText error>{formikCuenta.errors.banco}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        select
                                        name="tipo"
                                        size="small"
                                        label="Tipo"
                                        value={formikCuenta.values.tipo}
                                        onChange={formikCuenta.handleChange}
                                        onBlur={handleChangeFocusCuenta}
                                    >
                                        {type.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {formikCuenta.touched.tipo && formikCuenta.errors.tipo && (
                                        <FormHelperText error>{formikCuenta.errors.tipo}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        name="numero"
                                        size="small"
                                        value={formikCuenta.values.numero}
                                        onChange={formikCuenta.handleChange}
                                        onBlur={handleChangeFocusCuenta}
                                        type="text"
                                        label="N°"
                                    />
                                    {formikCuenta.touched.numero && formikCuenta.errors.numero && (
                                        <FormHelperText error>{formikCuenta.errors.numero}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        name="cci"
                                        size="small"
                                        value={formikCuenta.values.cci}
                                        onChange={formikCuenta.handleChange}
                                        onBlur={handleChangeFocusCuenta}
                                        type="text"
                                        label="CCI"
                                    />
                                    {formikCuenta.touched.cci && formikCuenta.errors.cci && (
                                        <FormHelperText error>{formikCuenta.errors.cci}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <TextField
                                        select
                                        name="estado"
                                        size="small"
                                        label="Estado"
                                        value={formikCuenta.values.estado}
                                        onChange={formikCuenta.handleChange}
                                        onBlur={handleChangeFocusCuenta}
                                    >
                                        {states.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {formikCuenta.touched.estado && formikCuenta.errors.estado && (
                                        <FormHelperText error>{formikCuenta.errors.estado}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <CustomLoadingButton
                                        loading={formikCuenta.isSubmitting}
                                        type="submit"
                                        startIcon={<AddCircleRoundedIcon />}
                                        // sx={{ width: '160px' }}
                                        variant="contained"
                                    >
                                        Agregar Cuenta
                                    </CustomLoadingButton>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl sx={{ height: formHeight }} fullWidth>
                                    <CustomOutlinedButton
                                        type="button"
                                        // sx={{ width: '160px' }}
                                        variant="outlined"
                                        startIcon={<CleaningServicesRoundedIcon />}
                                        onClick={() => {
                                            formikCuenta.resetForm();
                                        }}
                                    >
                                        Limpiar
                                    </CustomOutlinedButton>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <TableCuentas
                    cuentas={cuentasList}
                    setCuenta={setCuenta}
                    showModalDetalles={showModalDetalles}
                    showModalEditar={showModalEditar}
                />
                <CustomModal open={openModalDetalles} handleClose={hideModalDetalles} title="Detalle de Cuenta" styles={stylesModal}>
                    <div
                        style={{
                            minWidth: 'calc(80vw)',
                            display: 'flex',
                            width: '90%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '2rem 0',
                            margin: '0 auto'
                        }}
                    >
                        <Detalles cuenta={cuenta} />
                    </div>
                </CustomModal>
                <CustomModal open={openModalEditar} handleClose={hideModalEditar} title="Editar Cuenta" styles={stylesModal}>
                    <div
                        style={{
                            minWidth: 'calc(80vw)',
                            display: 'flex',
                            width: '90%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '2rem 0',
                            margin: '0 auto'
                        }}
                    >
                        <Editar cuenta={cuenta} />
                    </div>
                </CustomModal>
                <AlertDialog
                    open={dialogResult.open}
                    onClose={closeDialogResult}
                    title={dialogResult.title}
                    message={dialogResult.message}
                />
            </MainCard>
        </>
    );
};

export default memo(MisDatos);

const localeDataGrid = {
    // Root
    noRowsLabel: 'Sin Datos',
    noResultsOverlayLabel: 'Sin Resultados',
    errorOverlayDefaultLabel: 'Un error ocurrio',

    // Density selector toolbar button text
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Estandard',
    toolbarDensityComfortable: 'Confortable',

    // Columns selector toolbar button text
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Columnas Seleccionadas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros activos` : `${count} filtro activo`),

    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Encontrar columna',
    columnsPanelTextFieldPlaceholder: 'Titulo de columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

    // Filter panel text
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelOperators: 'Operadores',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Filtrar valor',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'igual',
    filterOperatorStartsWith: 'empieza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'esta despues',
    filterOperatorOnOrAfter: 'esta sobre o despues',
    filterOperatorBefore: 'esta antes',
    filterOperatorOnOrBefore: 'esta sobre o antes',
    filterOperatorIsEmpty: 'esta vacio',
    filterOperatorIsNotEmpty: 'no esta vacio',

    // Filter values text
    filterValueAny: 'algun',
    filterValueTrue: 'verdadero',
    filterValueFalse: 'falso',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar por ASC',
    columnMenuSortDesc: 'Ordenar por DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros activos` : `${count} filtro activo`),
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Ordenar',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1 ? `${count.toLocaleString()} filas seleccionadas` : `${count.toLocaleString()} fila seleccionada`,

    // Total rows footer text
    footerTotalRows: 'Total Rows:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selección de casilla',

    // Boolean cell text
    booleanCellTrueLabel: 'verdadero',
    booleanCellFalseLabel: 'falso',

    // Actions cell more text
    actionsCellMore: 'mas',

    // Column pinning text
    pinToLeft: 'Pin a la izquierda',
    pinToRight: 'Pin a la derecha',
    unpin: 'Desprender',

    // Tree Data
    treeDataGroupingHeaderName: 'Agrupar',
    treeDataExpand: 'mostrar hijo',
    treeDataCollapse: 'ocultar hijo',

    // Used core components translation keys
    MuiTablePagination: {
        getItemAriaLabel: (type) => {
            if (type === 'first') {
                return 'Ir a la primera página';
            }
            if (type === 'last') {
                return 'Ir a la última página';
            }
            if (type === 'next') {
                return 'Ir a la página siguiente';
            }
            // if (type === 'previous') {
            return 'Ir a la página anterior';
        },
        labelRowsPerPage: 'Filas por página:',
        labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
    }
};

export default localeDataGrid;

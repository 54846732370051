import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import useAuth from 'contexts/AuthContext';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import MisDatos from './MisDatos';

// assets
import { IconChevronRight } from '@tabler/icons';
import useSocketClient from 'contexts/SocketContext';
import Swal from 'sweetalert2';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const stylesModal = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    box: {
        display: 'flex',
        alignItems: 'center'
    }
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const socket = useSocketClient();
    const autenticate = useAuth();
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const { open: openMisDatos, show: showMisDatos, hide: hideMisDatos } = useModal();

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    useEffect(() => {
        socket.socketClient.on('solicitud:pendiente:backoffice', (data) => {
            if (autenticate.user.id === data.responzable.id) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Alerta',
                    text: `Tiene una solicitud pendiente de aprobar del Proveedor: ${data.proveedor.razonSocial} para la Sede: ${data.sede.name}`
                });
            }
        });
        socket.socketClient.on('solicitud:rejected:backoffice', (data) => {
            if (autenticate.user.proveedor?.id === data.proveedor.id) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Alerta',
                    text: `Una solicitud para la Sede:${data.sede.name} fue rechazada por:${data.responzable.fullName}`
                });
            }
        });
        socket.socketClient.on('solicitud:pendiente:seguridad_patrimonial', (data) => {
            if (autenticate.user.type.id === '07') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Alerta',
                    text: `Tiene una solicitud pendiente de aprobar de la sede:${data.sede.name} del Proveedor: ${data.proveedor.razonSocial}`
                });
            }
        });
        socket.socketClient.on('solicitud:rejected:seguridad_patrimonial', (data) => {
            if (autenticate.user.id === data.responzable.id) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Alerta',
                    text: `Una solicitud para la Sede: ${data.sede.name} fue rechazada por Seguridad patrimonial: ${data.currentUser}`
                });
            }
        });
        socket.socketClient.on('solicitud:accepted', (data) => {
            console.log(data);
            if (autenticate.user.proveedor?.id === data.proveedor.id) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Alerta',
                    text: `Una solicitud para la Sede: ${data.sede.name} fue aceptada!`
                });
            }
        });
        // eslint-disable-next-line consistent-return
        socket.socketClient.on('solicitud:rejected:seguridad', (data) => {
            let response = '';
            console.log(data.responzable.id);
            console.log(autenticate.user.id);
            if (data.responzable.id === autenticate.user.id || autenticate.user.proveedor?.id === data.proveedor.id) {
                console.log(data.responzable.id === autenticate.user.id);
                response = `Una solicitud del proveedor: ${data.proveedor.razonSocial} para la Sede: ${data.sede.name} fue rechaza por el Responsable de Seguridad!`;
                if (autenticate.user.proveedor?.id === data.proveedor.id) {
                    response = `Una solicitud para la Sede: ${data.sede.name} fue rechaza por el Responsable de Seguridad!`;
                }
                return Swal.fire({
                    icon: 'warning',
                    title: 'Alerta',
                    text: response
                });
            }
        });
        return () => {
            socket.socketClient.off('solicitud:pendiente:backoffice');
            socket.socketClient.off('solicitud:rejected:backoffice');
            socket.socketClient.off('solicitud:pendiente:seguridad_patrimonial');
            socket.socketClient.off('solicitud:rejected:seguridad_patrimonial');
            socket.socketClient.off('solicitud:accepted');
            socket.socketClient.off('solicitud:rejected:seguridad');
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} showMisDatos={showMisDatos} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            {/* <Customization /> */}
            <Modal open={openMisDatos} handleClose={hideMisDatos} title="Mis Datos" styles={stylesModal}>
                <div
                    style={{
                        minWidth: 'calc(80vw)',
                        display: 'flex',
                        width: '90%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2rem 0',
                        margin: '0 auto'
                    }}
                >
                    <MisDatos />
                </div>
            </Modal>
        </Box>
    );
};

export default MainLayout;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// routing
const CotizacionesListView = Loadable(lazy(() => import('views/cotizaciones/listar')));
const CotizacionesView = Loadable(lazy(() => import('views/cotizaciones/registrar')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/cotizaciones',
    element: <MainLayout />,
    children: [
        {
            path: '/listar',
            element: <CotizacionesListView />
        },
        {
            path: '/registrar',
            element: <CotizacionesView />
        }
    ]
};

export default MainRoutes;

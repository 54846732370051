import PropTypes from 'prop-types';
import { io } from 'socket.io-client';
import { createContext, useContext, useEffect, useState, useRef } from 'react';
import config from 'config';

const SocketContext = createContext();

export function SocketProvider({ children }) {
    const socketClient = useRef();
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        setConnected(false);
        socketClient.current = io(config.api.url.replace(/api/gi, ''));
        setConnected(true);
        console.log(socketClient.current);
        // setSocketClient(client);
    }, []);

    const store = {
        socketClient: socketClient.current,
        connected
    };

    return <SocketContext.Provider value={store}>{children}</SocketContext.Provider>;
}

export default function useSocketClient() {
    return useContext(SocketContext);
}

SocketProvider.propTypes = {
    children: PropTypes.any
};

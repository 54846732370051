/* eslint-disable */
import menuItems from 'menu-items';
import _ from 'lodash';

class Menus {
    /**
     *
     * @param {Array} menuItems
     * @param {string} userMenu
     */
    static compare(menuItems, userMenu) {
        const arrMenu = userMenu.split(' ');
        let response = [];
        let urls = [];

        for (const item of menuItems.items) {
            if (item?.children?.length > 0 && item?.type === 'group' && item?.children[0]?.type !== 'collapse') {
                item.children.forEach((menu) => {
                    if (arrMenu.includes(menu.id)) {
                        urls = [...urls, menu.url];

                        if (!response.includes((i) => i.id === item.id)) {
                            response = [...response, {
                                ...item,
                                children: [menu]
                            }];
                        } else {
                            response = response.map((menuItem) => {
                                if (menuItem.id === item.id) {
                                    menuItem.children.push(menu);
                                }

                                return menuItem;
                            });
                        }
                    }
                });

                continue;
            }

            if (item.children[0]?.children === undefined && arrMenu.includes(item.children[0].id)) {
                response = [...response, item];
                urls = [...urls, item.children[0].url];
                continue;
            }

            const items = item.children[0]?.children?.filter((menuItem) => arrMenu.includes(menuItem.id));
            if (items?.length > 0) {
                response = [...response, { ...item, children: [{ ...item.children[0], children: [...items] }] }];
                urls = [
                    ...urls,
                    ...items.map((item) => {
                        return item.url;
                    })
                ];
            }
        }
        return { items: response.sort((a, b) => a.order - b.order), urls };
    }

    static getAllItems() {
        return menuItems;
    }

    static getAll() {
        let response = [];

        for (const item of menuItems.items) {
            if (item.children[0]?.children === undefined) {
                const menu = {
                    id: item.id,
                    title: item.children[0].title,
                    icon: item.children[0].icon,
                    order: item.order,
                    submenus: [
                        {
                            id: item.children[0].id,
                            title: item.children[0].title,
                            icon: item.children[0].icon
                        }
                    ]
                };

                response = [...response, menu];
                continue;
            }

            const menu = {
                id: item.children[0].id,
                title: item.children[0].title,
                icon: item.children[0].icon,
                order: item.order,
                submenus: item.children[0].children.map((menu) => ({ id: menu.id, title: menu.title, icon: menu.icon }))
            };

            response = [...response, menu];
        }

        return response.sort((a, b) => a.order - b.order);
    }
}

export default Menus;

import { useState, useCallback, useRef, useEffect } from 'react';
import useSocketClient from 'contexts/SocketContext';
import Http from 'http-client';
import useLoader from './useLoader';

const defaultState = {
    data: null,
    url: '',
    interceptResponse: ({ data: { data } }) => data,
    socket: {},
    preventFirstTime: false
};

const useAPIQuery = (initialState = {}) => {
    const [state, setState] = useState({ ...defaultState, ...initialState });
    const socket = useSocketClient();
    const [data, setData] = useState(state.data);
    const loader = useLoader();
    const [error, setError] = useState(null);
    const events = useRef([]);
    const isMounted = useRef(false);
    // Este metodo exec permite conectanos al servicio ya mandandole un token de seguridad, por ahora sol metodos get
    const exec = useCallback(
        async (params = {}) => {
            // const opts = { ...defaultOptions, ...options };

            try {
                loader.startLoading();
                const response = await Http.get(state.url, { params });
                setData(state.interceptResponse(response));
            } catch (error) {
                setError(error);
            } finally {
                loader.stopLoading();
            }
        },
        [state]
    );

    const handleEvents = useCallback(() => {
        if (!Array.isArray(state.socket.handleEvents) || !socket.connected) return;

        // eslint-disable-next-line
        for (let i = 0; i < state.socket.handleEvents.length; i++) {
            const handleEvent = state.socket.handleEvents[i];
            const { event, action, shouldUpdateData } = handleEvent;
            events.current = [...events.current, event];

            // eslint-disable-next-line
            if (!event) continue;

            socket.socketClient.on(event, (data) => {
                if (typeof action === 'function') action(data);
                if (shouldUpdateData !== false) exec();
            });
        }

        // eslint-disable-next-line
        return () => {
            events.current.map((event) => socket.socketClient.off(event));
            events.current = [];
        };
    }, [socket.connected]);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        handleEvents();
    }, [handleEvents]);

    return {
        loading: loader.loading,
        data,
        error,
        exec,
        isMounted
    };
};

export default useAPIQuery;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const SeguridadPatrimonialSolicitudes = Loadable(lazy(() => import('views/seguridadPatrimonial/solicitudes')));
const seguridadPatrimonial = {
    path: '/seguridad-patrimonial/',
    element: <MainLayout />,
    children: [
        {
            path: '/solicitud',
            element: <SeguridadPatrimonialSolicitudes />
        }
    ]
};

export default seguridadPatrimonial;

import { Navigate } from 'react-router-dom';
import config from 'config';

// ==============================|| MAIN ROUTING ||============================== //

const HomeRoutes = {
    path: '/',
    element: <Navigate to={`${config.basename}/dashboard`} />
    // children: [
    //     {
    //         path: '/',
    //         element: <Navigate to="/dashboard" />
    //     }
    // ]
};

export default HomeRoutes;

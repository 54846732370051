// assets
import { IconUserCheck, IconFileCheck, IconReportSearch } from '@tabler/icons';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const ventas = {
    id: 'seguridad_patrimonial',
    type: 'group',
    order: 3,
    children: [
        {
            // id: '03',
            // title: 'Seguridad Patrimonial',
            // type: 'collapse',
            // icon: IconUserCheck,
            // children: [
            //     {
            id: '0301',
            title: 'Solicitudes',
            type: 'item',
            icon: IconUserCheck,
            url: '/seguridad-patrimonial/solicitud'
        }
        //     ]
        // }
    ]
};

export default ventas;

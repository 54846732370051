import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const ProveedorForm = Loadable(lazy(() => import('views/solicitudes/crear/Index')));
const ProveedorSolicitud = Loadable(lazy(() => import('views/backoffice/proveedores/solicitudesPorProveedor')));
const proveedorMantenimiento = {
    path: '/proveedor',
    element: <MainLayout />,
    children: [
        {
            path: '/solicitud',
            element: <ProveedorForm />
        }
    ]
};

export default proveedorMantenimiento;

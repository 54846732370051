import { useEffect, useState, useCallback, memo } from 'react';
import { Grid, TextField, FormControl, FormHelperText, MenuItem } from '@mui/material';

import AlertDialog from 'components/AlertDialog';
import CustomOutlinedButton from 'components/Button/OutlinedButton';
import CustomLoadingButton from 'components/Button/LoadingButton';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import { useFormik } from 'formik';

import * as Yup from 'yup';
import Http from 'http-client';

import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';

import PropTypes from 'prop-types';

const formHeight = '60px';

const EditarCuenta = ({ cuenta }) => {
    const [dialogResult, setDialogResult] = useState({
        open: false,
        title: '',
        message: ''
    });

    const states = [
        { id: 'A', value: 'Activo' },
        { id: 'I', value: 'Inactivo' }
    ];

    const type = [
        { id: 'S', value: 'Soles' },
        { id: 'D', value: 'Dólares Americanos' }
    ];

    const FormSchemaCuenta = Yup.object().shape({
        tipo: Yup.string().required('Requerido.'),
        numero: Yup.string().required('Requerido.'),
        cci: Yup.string().required('Requerido.'),
        estado: Yup.string().required('Requerido.')
    });

    const openDialogResult = useCallback((title, message) => {
        setDialogResult({
            open: true,
            title,
            message
        });
    }, []);

    const closeDialogResult = useCallback(() => {
        setDialogResult({
            ...dialogResult,
            open: false
        });
    }, []);

    const formikCuenta = useFormik({
        initialValues: {
            tipo: '',
            numero: '',
            cci: '',
            estado: ''
        },
        validationSchema: FormSchemaCuenta,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const body = {
                type: values.tipo,
                number: values.numero,
                cci: values.cci,
                state: values.estado
            };

            Http.patch(`/socios-cuentas/${cuenta.id}`, body)
                .then(({ status }) => {
                    if (status === 200) {
                        console.log(body);
                        openDialogResult('Cuenta actualizada', `Se actualizó la cuenta con éxito.`);
                        // resetForm();
                        return;
                    }

                    openDialogResult('Algo salió mal', `No se pudo actualizar la cuenta`);
                })
                .catch((error) => {
                    openDialogResult('Algo salió mal', `No se pudo actualizar la cuenta`);
                    console.log(error.response);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    const handleChangeFocusCuenta = useCallback(({ target: { name, value } }) => {
        formikCuenta.setFieldValue(name, value?.trim(), true);
    }, []);

    useEffect(() => {
        formikCuenta.setFieldValue('tipo', cuenta.type || '', true);
        formikCuenta.setFieldValue('numero', cuenta.number || '', true);
        formikCuenta.setFieldValue('cci', cuenta.cci || '', true);
        formikCuenta.setFieldValue('estado', cuenta.state || '', true);
    }, []);

    return (
        <>
            <form noValidate onSubmit={formikCuenta.handleSubmit}>
                <Grid item container direction="row" justifyContent="center" spacing={1.5} xs={12} padding={2}>
                    <Grid item container spacing={1} alignItems="center" justifyContent="center" xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={6} md={8}>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <TextField
                                    disabled
                                    sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            WebkitTextFillColor: 'black'
                                        }
                                    }}
                                    name="banco"
                                    size="small"
                                    label="Banco"
                                    value={cuenta.bank.description}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <TextField
                                    select
                                    name="tipo"
                                    size="small"
                                    label="Tipo"
                                    value={formikCuenta.values.tipo}
                                    onChange={formikCuenta.handleChange}
                                    onBlur={handleChangeFocusCuenta}
                                >
                                    {type.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {formikCuenta.touched.tipo && formikCuenta.errors.tipo && (
                                    <FormHelperText error>{formikCuenta.errors.tipo}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <TextField
                                    name="numero"
                                    size="small"
                                    value={formikCuenta.values.numero}
                                    onChange={formikCuenta.handleChange}
                                    onBlur={handleChangeFocusCuenta}
                                    type="text"
                                    label="N°"
                                />
                                {formikCuenta.touched.numero && formikCuenta.errors.numero && (
                                    <FormHelperText error>{formikCuenta.errors.numero}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <TextField
                                    name="cci"
                                    size="small"
                                    value={formikCuenta.values.cci}
                                    onChange={formikCuenta.handleChange}
                                    onBlur={handleChangeFocusCuenta}
                                    type="text"
                                    label="CCI"
                                />
                                {formikCuenta.touched.cci && formikCuenta.errors.cci && (
                                    <FormHelperText error>{formikCuenta.errors.cci}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <TextField
                                    select
                                    name="estado"
                                    size="small"
                                    label="Estado"
                                    value={formikCuenta.values.estado}
                                    onChange={formikCuenta.handleChange}
                                    onBlur={handleChangeFocusCuenta}
                                >
                                    {states.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {formikCuenta.touched.estado && formikCuenta.errors.estado && (
                                    <FormHelperText error>{formikCuenta.errors.estado}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <CustomLoadingButton
                                    loading={formikCuenta.isSubmitting}
                                    type="submit"
                                    startIcon={<SaveRoundedIcon />}
                                    // sx={{ width: '160px' }}
                                    variant="contained"
                                >
                                    Guardar
                                </CustomLoadingButton>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl sx={{ height: formHeight }} fullWidth>
                                <CustomOutlinedButton
                                    type="button"
                                    // sx={{ width: '160px' }}
                                    variant="outlined"
                                    startIcon={<CleaningServicesRoundedIcon />}
                                    onClick={() => {
                                        formikCuenta.resetForm();
                                    }}
                                >
                                    Limpiar
                                </CustomOutlinedButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <AlertDialog open={dialogResult.open} onClose={closeDialogResult} title={dialogResult.title} message={dialogResult.message} />
        </>
    );
};

EditarCuenta.propTypes = {
    cuenta: PropTypes.object.isRequired
};

export default memo(EditarCuenta);

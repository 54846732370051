import { useState } from 'react';

const useModal = (initialState = false) => {
    const [open, setOpen] = useState(initialState);

    const show = () => setOpen(true);
    const hide = () => setOpen(false);

    return {
        open,
        show,
        hide
    };
};

export default useModal;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//  routing
const ProductosView = Loadable(lazy(() => import('views/productos/Registrar')));
const ConsultarStockView = Loadable(lazy(() => import('views/productos/ConsultaStock')));
const CategoriasView = Loadable(lazy(() => import('views/productos/Categorias')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/productos',
    element: <MainLayout />,
    children: [
        {
            path: '/registrar',
            element: <ProductosView />
        },
        {
            path: '/categorias',
            element: <CategoriasView />
        },
        {
            path: '/consultar-stock',
            element: <ConsultarStockView />
        }
    ]
};

export default MainRoutes;

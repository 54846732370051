import PropTypes from 'prop-types';
import Loader from 'ui-component/Loader';
import SetPassword from 'views/pages/authentication/authentication/SetPassword';
import { createContext, useState, useContext, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

// Libs
import Http from 'http-client';
import Menus from 'classes/Menu';

import config from 'config';

const AuthContext = createContext();

function evalError(errorCode) {
    let errorMessage = '';

    switch (errorCode) {
        case 'INCORRECT_PASSWORD':
            errorMessage = 'La contraseña es incorrecta';
            break;

        case 'USER_NOT_FOUND':
            errorMessage = 'Usuario no encontrado.';
            break;

        default:
            errorMessage = 'Algo salio mal, por favor vuelve a intentarlo.';
            break;
    }

    return errorMessage;
}

export function AuthProvider({ children }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // true
    const [authorized, setAuthorized] = useState(false);
    const urlPrefix = config.basename;
    const publicPaths = [`${urlPrefix}/login`];

    const location = useLocation();
    const navigate = useNavigate();
    const logout = (callback) => {
        if (typeof callback === 'function') callback();
        setLoading(false);
        setUser(null);
        removeCookie('user');
        navigate(`${urlPrefix}/login`);
        setAuthorized(true);
    };

    const login = async (brand, username, password) => {
        try {
            setLoading(true);
            const {
                data: { data: response }
            } = await Http.post(
                '/auth/signIn',
                {
                    username,
                    password,
                    brand
                },
                {},
                {
                    includeAccessToken: false,
                    disableTokenRefresh: true
                }
            );
            const {
                user: {
                    id,
                    email,
                    phone,
                    firstName,
                    lastName,
                    fullName,
                    passwordType,
                    state,
                    menu,
                    documentTypeData: documentType,
                    type,
                    socio,
                    proveedor,
                    sede
                },
                accessToken,
                refreshToken
            } = response;

            const newUser = {
                id,
                email,
                phone,
                firstName,
                lastName,
                fullName,
                passwordType,
                state,
                menu,
                documentType,
                type,
                accessToken,
                refreshToken,
                socio,
                proveedor,
                sede
            };

            if (newUser.passwordType === '2') {
                setCookie('user', newUser, {
                    maxAge: 3600
                });
            }

            Http.setAccessToken(accessToken);
            Http.setRefreshToken(refreshToken);

            setUser(newUser);
            navigate(`${urlPrefix}/`);
        } catch (error) {
            const code = error?.response?.data?.code;
            setError(evalError(code));
        } finally {
            setLoading(false);
        }
    };

    const getUrlsPermitidas = (userMenu) => {
        const menuItems = Menus.getAllItems();
        const urlsPermitidas = Menus.compare(menuItems, userMenu);
        return urlsPermitidas;
    };

    const updatePassword = async (password, confirmPassword) => {
        if (password !== confirmPassword) throw new Error('Las contraseñas no coinciden.');
        try {
            await Http.patch(`/users/update-password/${user?.id}`, { password });
            setUser((prevState) => {
                const newUser = {
                    ...prevState,
                    passwordType: '2'
                };

                setCookie('user', newUser, {
                    maxAge: 3600
                });

                return newUser;
            });

            navigate('/');
        } catch (error) {
            throw new Error('Algo salio mal.');
        }
    };

    const verifyAccess = (user) => {
        const userMenu = user?.menu || null;
        const pathname = location.pathname;

        if (pathname === `${urlPrefix}/login` && user) {
            logout();
        }

        if (userMenu === null) {
            navigate(`${urlPrefix}/login`);
            setAuthorized(true);
            return;
        }

        if (publicPaths.indexOf(pathname) !== -1) {
            setAuthorized(true);
            return;
        }

        const urlsPermitidas = getUrlsPermitidas(userMenu).urls.map((url) => `${urlPrefix}${url}`);
        const hasAccess = urlsPermitidas.includes(pathname);

        if (urlsPermitidas.length === 0) {
            navigate(`${urlPrefix}/login`);
            setAuthorized(true);
            return;
        }

        if (!hasAccess) {
            navigate(`${urlPrefix}${urlsPermitidas[0]}`);
            setAuthorized(true);
            return;
        }

        setAuthorized(true);
    };

    const updateTokens = (accessToken, refreshToken) => {
        if (!accessToken || !refreshToken) return;
        setUser((prevState) => {
            const newUser = { ...prevState, accessToken, refreshToken };
            setCookie('user', newUser, {
                maxAge: 3600
            });
            return newUser;
        });
    };

    useEffect(() => {
        const userCookie = cookies.user;
        if (!userCookie) return;

        setUser(userCookie === undefined ? null : userCookie);
        Http.setAccessToken(userCookie.accessToken);
        Http.setRefreshToken(userCookie.refreshToken);

        Http.onRefreshToken().subscribe({
            next({ accessToken, refreshToken }) {
                updateTokens(accessToken, refreshToken);
            },
            error() {
                logout();
            }
        });
    }, []);

    useEffect(() => {
        if (error) setError(null);
        const userCookie = cookies.user;
        verifyAccess(userCookie);
    }, [location.pathname]);

    const store = {
        user,
        error,
        loading,
        login,
        logout,
        updatePassword
    };

    if (loading || !authorized) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider value={store}>
            {user?.passwordType === '1' ? <SetPassword updatePassword={updatePassword} /> : children}
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}

AuthProvider.propTypes = {
    children: PropTypes.any
};

import { memo } from 'react';
import { Grid, TextField, FormControl } from '@mui/material';

import useAuth from 'contexts/AuthContext';
import PropTypes from 'prop-types';

const formHeight = '60px';

const DetalleCuenta = ({ cuenta }) => {
    const { user } = useAuth();

    return (
        <>
            <Grid item container direction="row" justifyContent="center" spacing={1.5} xs={12} padding={2}>
                <Grid item container spacing={1} alignItems="center" justifyContent="center" xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={6} md={8}>
                        <FormControl sx={{ height: formHeight }} fullWidth>
                            <TextField
                                disabled
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'black'
                                    }
                                }}
                                name="banco"
                                size="small"
                                label="Banco"
                                value={cuenta.bank.description}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{ height: formHeight }} fullWidth>
                            <TextField
                                disabled
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'black'
                                    }
                                }}
                                name="tipo"
                                size="small"
                                label="Tipo"
                                value={cuenta.type === 'S' ? 'Soles' : 'Dólares Americanos'}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{ height: formHeight }} fullWidth>
                            <TextField
                                disabled
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'black'
                                    }
                                }}
                                name="numero"
                                size="small"
                                value={cuenta.number}
                                type="text"
                                label="N°"
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{ height: formHeight }} fullWidth>
                            <TextField
                                disabled
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'black'
                                    }
                                }}
                                name="cci"
                                size="small"
                                value={cuenta.cci}
                                type="text"
                                label="CCI"
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{ height: formHeight }} fullWidth>
                            <TextField
                                disabled
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'black'
                                    }
                                }}
                                name="estado"
                                size="small"
                                label="Estado"
                                value={cuenta.state === 'A' ? 'Activo' : 'Inactivo'}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

DetalleCuenta.propTypes = {
    cuenta: PropTypes.object.isRequired
};

export default memo(DetalleCuenta);

// assets
import { IconTruckDelivery, IconFileImport } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const proveedores = {
    id: 'proveedores',
    type: 'group',
    order: 1,
    children: [
        // {
        //     id: '02',
        //     title: 'Proveedores',
        //     type: 'collapse',
        //     icon: IconTruckDelivery,
        //     children: [
        {
            id: '0201',
            title: 'Solicitudes',
            type: 'item',
            icon: IconTruckDelivery,
            url: '/proveedor/solicitud'
        }
        //     ]
        // }
    ]
};

export default proveedores;

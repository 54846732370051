import { Navigate } from 'react-router-dom';
import config from 'config';

// ==============================|| MAIN ROUTING ||============================== //

const url = `${config.basename}/dashboard`;

const MainRoutes = {
    path: '*',
    element: <Navigate to={url} />
    // children: [
    //     {
    //         path: '*',
    //         element: <Navigate to={url} />
    //     }
    // ]
};

export default MainRoutes;

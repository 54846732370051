import { useSelector } from 'react-redux';

import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { AuthProvider } from 'contexts/AuthContext';
import { SocketProvider } from 'contexts/SocketContext';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import './App.css';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <CookiesProvider>
            <SocketProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <AuthProvider>
                                <Routes />
                            </AuthProvider>
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </SocketProvider>
        </CookiesProvider>
    );
};

export default App;

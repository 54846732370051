import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import HomeRoutes from './HomeRoutes';
import MainRoutes from './MainRoutes';
import CotizacionesRoutes from './CotizacionesRoutes';
import VentasRoutes from './VentasRoutes';
import ProductosRoutes from './ProductosRoutes';
import UsuariosRoutes from './UsuariosRoutes';
import OtherRoutes from './OtherRoutes';
import ClientsRoutes from './ClientsRoutes';
import ProveedorRoutes from './ProveedorRoutes';
import SeguridadPatrimonial from './SeguridadPatrimonial';

import config from 'config';
import coodinadorMantenimiento from './coodinadorMantenimiento';
import ControlActivosRoutes from './ControlActivos';
import responsableSeguridad from './ResponsableSeguridadRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes(
        [
            MainRoutes,
            ProveedorRoutes,
            responsableSeguridad,
            SeguridadPatrimonial,
            coodinadorMantenimiento,
            CotizacionesRoutes,
            VentasRoutes,
            ClientsRoutes,
            ProductosRoutes,
            AuthenticationRoutes,
            UsuariosRoutes,
            OtherRoutes,
            ControlActivosRoutes
        ],
        config.basename
    );
}

import useAuth from 'contexts/AuthContext';
import config from 'config';

import Logo from 'assets/images/innova_logo.png';

const SocioLogo = () => {
    const { user } = useAuth();

    return (
        <div
            style={{
                objectFit: 'fill',
                width: '100%',
                maxWidth: '300px',
                height: '100px',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <img style={{ display: 'block', width: '80%', height: '60%' }} src={Logo} alt="..." />
        </div>
    );
};

export default SocioLogo;

// assets
import { IconUserPlus, IconUser, IconUsers, IconTruckDelivery } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconUsers,
    IconUserPlus
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const usuarios = {
    id: 'usuarios',
    type: 'group',
    title: '',
    order: 7,
    children: [
        {
            id: '0401',
            title: 'Usuario',
            type: 'item',
            icon: IconTruckDelivery,
            url: '/coordinadorMantenimiento/usuario'
        }
    ]
};

export default usuarios;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//  routing
const ClientsView = Loadable(lazy(() => import('views/clients/Registrar')));
const ClientsSearchView = Loadable(lazy(() => import('views/clients/Search')));

// ==============================|| MAIN ROUTING ||============================== //

const ClientsRoutes = {
    path: '/clientes',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <ClientsSearchView />
        },
        {
            path: '/registrar',
            element: <ClientsView />
        }
    ]
};

export default ClientsRoutes;

import Backoffice from './backoffice';
import Proveedor from './proveerdor';
import Usuarios from './usuarios';
import SeguridadPatrimonial from './seguridad_patrimonial';
import responsableSeguridad from './responsable_seguridad';

// ==============================|| MENU ITEMS ||============================== //

/**
 *  01 - Dashboard
 *  02 - Cotizaciones
 *  03 - Productos
 *  04 - Clientes
 *  05 - Ventas
 *  06 - Usuarios
 *  07 - Almacen
 *  08 - Activos
 */

const menuItems = {
    items: [Backoffice, Proveedor, Usuarios, SeguridadPatrimonial, responsableSeguridad]
};

export default menuItems;

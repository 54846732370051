import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//  routing
const RegistroActivosView = Loadable(lazy(() => import('views/activos/registro_activos')));

// ==============================|| MAIN ROUTING ||============================== //

const ControlActivosRoutes = {
    path: '/control-de-activos',
    element: <MainLayout />,
    children: [
        {
            path: '/registrar',
            element: <RegistroActivosView />
        }
        // {
        //     path: '/orden-de-pedidos',
        //     element: <OrdenPedidosView />
        // }
    ]
};

export default ControlActivosRoutes;

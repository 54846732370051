// assets
import { IconFileAnalytics, IconTruckDelivery, IconFileImport, IconHistory } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const coodinadorMantenimiento = {
    id: 'back_office',
    type: 'group',
    order: 1,
    children: [
        // {
        //     id: '01',
        //     title: 'Backoffice',
        //     type: 'item',
        //     icon: IconFileAnalytics,
        //     children: [
        {
            id: '0101',
            title: 'Solicitudes',
            type: 'item',
            icon: IconFileAnalytics,
            url: '/coordinadorMantenimiento/solicitud'
        },
        {
            id: '0102',
            title: 'Proveedores',
            type: 'item',
            icon: IconTruckDelivery,
            url: '/coordinadorMantenimiento/proveedor'
        }
        // {
        //     id: '0103',
        //     title: 'Usuario',
        //     type: 'item',
        //     icon: IconTruckDelivery,
        //     url: '/coordinadorMantenimiento/usuario'
        // }
    ]
    //     }
    // ]
};

export default coodinadorMantenimiento;

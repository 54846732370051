import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthRoute from 'components/AuthRoute';

// usuarios routing
const UsuariosListar = Loadable(lazy(() => import('views/usuarios/listar/index')));
const UsuariosRegistrar = Loadable(lazy(() => import('views/usuarios/registrar/index')));
const ProveedoresView = Loadable(lazy(() => import('views/backoffice/proveedores/tablaProveedores')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/usuarios',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <ProveedoresView /> // <UsuariosListar />
                },
                {
                    path: '/registrar',
                    element: <UsuariosRegistrar />
                }
            ]
        }
    ]
};

export default MainRoutes;

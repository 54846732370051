import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//  routing
const ProveedoresView = Loadable(lazy(() => import('views/backoffice/proveedores')));
const SolicitudesView = Loadable(lazy(() => import('views/backoffice/solicitudes/crear')));

const BackOfficeSolicitud = Loadable(lazy(() => import('views/backoffice/solicitudes/tableSolicitud')));
const ProveedorCrud = Loadable(lazy(() => import('views/backoffice/proveedores/tablaProveedores')));
const UsuarioCrud = Loadable(lazy(() => import('views/usuarios/registrar/index')));
// const OrdenPedidosView = Loadable(lazy(() => import('views/almacen/orden_pedidos')));

const coodinadorMantenimiento = {
    path: '/coordinadorMantenimiento',
    element: <MainLayout />,
    children: [
        {
            path: '/solicitud',
            element: <BackOfficeSolicitud />
        },
        {
            // <ProveedoresView />
            path: '/proveedor',
            element: <ProveedorCrud />
        },
        {
            path: '/usuario',
            element: <UsuarioCrud />
        }
    ]
};

export default coodinadorMantenimiento;
